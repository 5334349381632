<template>
  <cart-entwash-gifts-ranged
    v-if="appConfig.VueSettingsPreRun.CartLayout === 'Entwash'"
    :call-for-popup="callForPopup"
    :cart-to-pay-with-money="cartToPayWithMoney"
    :current-index="currentIndex"
    :gifts="gifts"
    :milestone-separated="milestoneSeparated"
    :milestones="milestones"
    :pay-separated="paySeparated"
  />
</template>

<script setup lang="ts">
import type { GiftRanged } from '~types/menuStore'

const clientStore = useClientStore()
const menuStore = useMenuStore()
const popupStore = usePopupStore()

const appConfig = useAppConfig()

onMounted(() => {
  if (import.meta.client) {
    menuStore.initGiftsRanged()
  }
})

const gifts = ref<GiftRanged[]>([])
const milestones = ref<number[]>([])
const paySeparated = ref<number[]>([])
const milestoneSeparated = ref<number[]>([])

const currentIndex = computed<number>(() => {
  let result = 0

  for (const milestone of milestones.value) {
    if (milestone >= cartToPayWithMoney.value) break

    result++
  }

  return result
})

function callForPopup(): void {
  popupStore.openPopup({
    popupClosable: true,
    popupName: 'menuGiftsPopup',
    popupProperties: new Map<string, unknown>([
      ['allowMultiple', menuStore.GiftsRanged.data?.AllowMultipleGifts],
      ['gifts', menuStore.GiftsRanged.data?.Gifts],
      ['type', 'ranged']
    ])
  })
}

const cartToPayWithMoney = computed<number>(() =>
  menuStore.GiftsRanged.data?.EnableCountPoints
    ? (clientStore.ClientState.data?.Cart?.ToPayWithMoneyWithoutExcludedItems ?? 0) -
      (clientStore.ClientState.data?.Cart?.ToPayWithPoints?.ToConsider ?? 0)
    : (clientStore.ClientState.data?.Cart?.ToPayWithMoneyWithoutExcludedItems ?? 0)
)

function paySeparatedChange(): void {
  const paySeparatedLocal = [] as number[]

  for (let index = 0; index < milestones.value.length; index++) {
    if (index === 0) paySeparatedLocal.push(cartToPayWithMoney.value)
    else
      paySeparatedLocal.push(cartToPayWithMoney.value - milestones.value[index - 1])
  }

  paySeparated.value = [...paySeparatedLocal]
}
watch(
  () => cartToPayWithMoney.value,
  () => {
    paySeparatedChange()
  }
)

watch(
  () => menuStore.GiftsRanged.data?.Gifts,
  (newValue, oldValue) => {
    if (
      newValue &&
      newValue.map((g) => g.GiftProductID).join('-') !==
        (oldValue ?? []).map((g) => g.GiftProductID).join('-')
    ) {
      milestones.value = newValue
        ? [...new Set(newValue.map((item) => item.AvailableFromCartSum))].sort(
            (a, b) => {
              return a - b
            }
          )
        : []
      gifts.value = (newValue ?? []).sort((a: GiftRanged, b: GiftRanged) => {
        return a.AvailableFromCartSum > b.AvailableFromCartSum ? 1 : -1
      })
      paySeparatedChange()
      const milestoneSeparatedLocal = [] as number[]

      let previousMilestone = 0

      for (const milestone of milestones.value) {
        milestoneSeparatedLocal.push(milestone - previousMilestone)
        previousMilestone = milestone
      }

      milestoneSeparated.value = [...milestoneSeparatedLocal]
    }
  },
  { deep: true, immediate: true }
)
</script>
